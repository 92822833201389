import React from "react"

export default function Layout({ children }) {

            // <img src="https://bulma.io/images/bulma-logo.png" alt="Bulma: Free, open source, and modern CSS framework based on Flexbox" width="112" height="28"></img>
          //
          // <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false">
          //   <span aria-hidden="true"></span>
          //   <span aria-hidden="true"></span>
          //   <span aria-hidden="true"></span>
          // </a>
  return (
    <div>
      <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
          CSV Searcher
          </a>
        </div>
      </nav>
      {children}
    </div>
  )
}
