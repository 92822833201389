import * as React from "react"
import SearchBar from "../components/searchbar"
// https://react-icons.github.io/react-icons/icons?name=im
import { ImCheckmark } from 'react-icons/im';
import { ImCross } from 'react-icons/im';

// class ProductCategoryRow extends React.Component {
//     render() {
//           const category = this.props.category;
//           return (
//                   <tr>
//                     <th colSpan="2">
//                       {category}
//                     </th>
//                   </tr>
//                 );
//         }
// }

class TableRow extends React.Component {
    render() {
          const product = this.props.product;
          const headers = this.props.headers;

          const cells = headers.map((header, i) => {
            // console.log(header.toString());

            var val = product[header.toString()];

            if (val != null && ["true", "yes", "done"].includes(val.toString().toLowerCase())) {
              val = <ImCheckmark />
            } else if (val != null && ["false", "no", "fail"].includes(val.toString().toLowerCase())) {
              val = <ImCross />
            }

            return (<td>{val}</td>)
          });

          return (
                  <tr>
                    {cells}
                  </tr>
                );
        }
}

class HeaderColumn extends React.Component {
    render() {
          const header = this.props.header;
          return (
                    <th>{header}</th>
                );
        }
}

class ProductTable extends React.Component {
  render() {
    const filters = this.props.filters;
    const rows = [];
    const headers = [];

    const allowed = (product, index, self) => {
      var result = true;

      filters.forEach((f) => {
        // TODO filter booleans
        // TODO filter blanks
        if (product[f.key] && f.value && !product[f.key].toString().toLowerCase().startsWith(f.value.toLowerCase())) {
          result = false;
          return
        }
      });
      return result;
    };

    var allowedProducts = this.props.products.filter(allowed);
    var index = 0;

    allowedProducts.forEach((product) => {
      rows.push(
        <TableRow
        product={product}
        key={index}
        headers={this.props.headers}
        />
      );
      index++;
    });

    this.props.headers.forEach((header) => {
      headers.push(
        <HeaderColumn
        header={header}
        />
      );
    });


    return (
          <div className="table-container">
                <table className="table is-striped is-fullwidth">
                  <thead>
                    <tr>
                      {headers}
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
          </div>
    );
  }
}


class FilterableProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
    };

    // TODO build header row

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);

  }

  handleFilterTextChange(filters) {
    this.setState({
      filters: filters,
    });
  }

    render() {
      var headers = Object.keys(this.props.products[0]);
          return (
                  <div>
                    <SearchBar
                      headers={headers}
                      products={this.props.products}
                      onFilterTextChange={this.handleFilterTextChange}
                    />
                    <ProductTable
                      products={this.props.products}
                      headers={headers}
                      filters={this.state.filters}
                    />
                  </div>
                );
        }
}

export default FilterableProductTable;
