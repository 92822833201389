import React from "react"
import FilterableProductTable from "../components/table"
import FileReader from "../components/file_reader"

class App extends React.Component {

  constructor(props) {
    super(props);

    this.handleCSV = this.handleCSV.bind(this);

    this.state = {
      items: [],
      display: "Upload CSV",
    };
  }

  handleCSV(data) {
    var result = data.map(x => x["data"]);

    this.setState({
      items: result,
      display: "Table",
    });
  }

  useSampleData(event) {
    const example = [
      {category: 'Sporting Goods', price: '$49.99', stocked: true, name: 'Football'},
      {category: 'Sporting Goods', price: '$9.99', stocked: true, name: 'Baseball'},
      {category: 'Sporting Goods', price: '$29.99', stocked: false, name: 'Basketball'},
      {category: 'Electronics', price: '$99.99', stocked: true, name: 'iPod Touch'},
      {category: 'Electronics', price: '$399.99', stocked: false, name: 'iPhone 5'},
      {category: 'Electronics', price: '$199.99', stocked: true, name: 'Nexus 7'}
    ];

    this.setState({
      items: example,
      display: "Table",
    });
  }


  render() {
    const pageContent =  [];

    if (this.state.display === "Upload CSV") {

      // pageContent.push(
      //   <p>This is a local friendly CSV viewer to make it easier to filter</p>
      // )

      pageContent.push(
        <section className="hero is-fullheight-with-navbar">
          <div className="hero-body">
          <div className="container">
              <FileReader
              handleCSV={this.handleCSV}
              />
          </div>
          </div>
          <div className="hero-foot">
            <div className="container">
              <button
              className="button is-secondary"
              onClick={event => this.useSampleData(event)}
              >Use example data</button>
            </div>
          </div>
        </section>
      )

      pageContent.push(
      )
    } else if (this.state.display === "Table"){
      pageContent.push(
        <FilterableProductTable
        products={this.state.items}
        />
      )
  }




    return (
      <div>
        <title>{this.state.display}</title>
          {pageContent}
      </div>
    );
  }
}

export default App
