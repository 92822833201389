import React from "react";
import { ImSearch } from 'react-icons/im';

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      activeIndex: 0,
      matches: [],
      query: "",
      selected: false,
      filterOptions: this.props.headers,
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.updateQuery = this.updateQuery.bind(this);

    this.inputbox = React.createRef();
  }

  componentDidMount() {
    this.inputbox.current.focus();
  }

  handleKeyPress(event) {
    const { activeIndex, matches } = this.state;

    switch (event.which) {
      case 13: // Enter key
        if (matches.length) {
          this.setState({
            activeIndex: 0,
            // matches: [matches[activeIndex] + "="],
            matches: [],
            query: matches[activeIndex],
            selected: false
          });
        }

        this.handleSelection(this.state.query);
        break;
      case 38: // Up arrow
        this.setState({
          activeIndex: activeIndex >= 1 ? activeIndex - 1 : 0
        });
        break;
      case 40: // Down arrow
        this.setState({
          activeIndex:
          activeIndex < matches.length - 1
          ? activeIndex + 1
          : matches.length - 1
        });
        break;
      case 187: // Equals
        break;
      default:
        break;
    }
  }

  handleSelection(selection) {
    if (/\w=.+/.test(selection)) {
      // Add new tag callback
      this.props.handleSelection(selection);

      this.setState({
        activeIndex: 0,
        query: "",
        matches: [],
        filterOptions: this.props.headers,
        selected: false
      });
    }
  }

  handleClickSelection(event, selection) {
    event.preventDefault();

    this.setState({
      activeIndex: 0,
      query: selection,
      matches: [],
      selected: false
    });

    this.handleSelection(selection);
  }

  updateQuery(e) {
    if (!this.state.selected) {
      const query = e.target.value;

      var matches = [];
      const lowerHeaders = this.props.headers.map(v => v.toLowerCase());

      var prefixHeaderMatches = lowerHeaders.filter(header => query.toLowerCase().startsWith(header + "="));

      if (prefixHeaderMatches.length === 1 ) {
        // if it starts with 'header='

        var header = prefixHeaderMatches[0];
        // if (header + "=" === query.toLowerCase()) {
        //
        // }

        if (this.state.suggestionType !== "VALUES") {
          const unique = (value, index, self) => {
            return self.indexOf(value) === index
          }

          var index = lowerHeaders.indexOf(header);
          var realHeader = this.props.headers[index];

          const columns = this.props.data.map(row => query + row[realHeader]).filter(unique);

          this.setState({
            filterOptions: columns,
            suggestionType: "VALUES",
          });
        }
      } else if (this.state.suggestionType !== "HEADERS") {
        // if a header hasn't been found
        this.setState({
          filterOptions: this.props.headers,
          suggestionType: "HEADERS",
        });
      }

            // this.setState({
            //   matches: [query + "="],

      if (query.length >= 1 ) {
        if (this.state.matches.length === 1 && query === this.state.matches[0]) {
              matches = [query + "="];
        } else {
          matches = this.state.filterOptions.filter(
            item => item.toUpperCase().indexOf(query.toUpperCase()) >= 0
          );
        }

        if (/\w=$/.test(query)) {
          this.props.handleQueryChange(query);
        // } else if (/\w=/.test(query)) {
        } else {
          this.props.handleQueryChange("");
        }

      }

      this.setState({
        matches: matches,
        query,
      });

    } else {
      if (e.nativeEvent.inputType === "deleteContentBackward") {
        this.setState({
          matches: [],
          query: "",
          selected: false
        });
        // TODO callback to search bar to remove
        // this.handleSelection("");
      }
    }
  }

  render() {
    const { label, name, placeholder } = this.props;
    const { activeIndex, matches, query } = this.state;

    return (
      <div className="container">
        {label && <label className="label">{label}</label>}
        <div className="control has-icons-left">
          <div className={`dropdown ${matches.length > 0 ? "is-active" : ""}`}>
            <div className="dropdown-trigger">
            <input
            type="text"
            className="input is-primary is-focused is-large"
            name={name}
            value={query}
            onChange={this.updateQuery}
            onKeyDown={this.handleKeyPress}
            placeholder={placeholder}
            autocomplete="off"
            ref={this.inputbox}
            />
            <span class="icon is-medium is-left">
              <ImSearch />
            </span>
            </div>
            <div className="dropdown-menu">
            {matches.length > 0 && (
              <div className="dropdown-content">
              {matches.map((match, index) => (
                <a
                className={`dropdown-item ${
                  index === activeIndex ? "is-active" : ""
                }`}
                href="/"
                key={match}
                onClick={event => this.handleClickSelection(event, match)}
                >
                {match}
                </a>
              ))}
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Autocomplete;
