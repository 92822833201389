import React from "react";

class FilterTag extends React.Component {
  render() {
    return (
      <span className="tag">
        {this.props.name}
        <button
        className="delete"
        onClick={event => this.props.onDelete(event, this.props.id)}
        >close</button>
      </span>
    );
  }
}

export default FilterTag;
