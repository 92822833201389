import * as React from "react"
import { CSVReader } from 'react-papaparse'
import { BiUpload } from 'react-icons/bi';

class FileReader extends React.Component {

    handleOnDrop = (data) => {
          this.props.handleCSV(data)
        }

    handleOnError = (err, file, inputElem, reason) => {
          console.log(err)
        }

    render() {
          return (
                <div class="container has-text-centered">
                      <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        config={ {header: true } }
                      >
                        <span>Drop CSV file here or click here to select. </span>
                        <span className="icon is-large"><BiUpload/></span>
                      </CSVReader>
                </div>
                )
        }

}
export default FileReader;
