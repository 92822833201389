import React from "react";
import Autocomplete from "../components/autocomplete"
import FilterTag from "../components/filter_tag"

class SearchBar extends React.Component {

  constructor(props) {
    super(props);
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleFilterSelect = this.handleFilterSelect.bind(this);
    this.handleTagDelete = this.handleTagDelete.bind(this);

    this.state = {
      filters: [],
    }
  }

  handleFilterSelect(selection) {
    var res = selection.split("=");

    this.state.filters.push({ key: res[0], value: res[1] });
    // this.state.filters[res[0]] = res[1];

    // this.state.tags.push(
    //   <FilterTag
    //   name={selection}
    //   key={res[0]}
    //   // onDelete={this.handleTagDelete}
    //   />
    // )
    //
    this.props.onFilterTextChange(this.state.filters);
  }

  handleFilterTextChange(selection) {
    // TODO Push new tag
    // var res = selection.split("=");
    // this.props.onFilterTextChange([[res[0], res[1]]]);
  }

  handleTagDelete(event, keyId) {
    const updatedFilters = this.state.filters.filter(f => f.key !== keyId);

    this.setState({
      filters: updatedFilters,
    });

    this.props.onFilterTextChange(updatedFilters);
  }

  render() {
    const uiTags = [];

    this.state.filters.forEach((filter) => {
      uiTags.push(
        <FilterTag
        name={filter.key + " = '" + filter.value + "'"}
        id={filter.key}
        key={filter.key}
        onDelete={this.handleTagDelete}
        />);
    });

    return (
        <div className="section">
          <div className="container">
            <Autocomplete
              name="fruit"
              label=""
              placeholder="Begin typing to filter..."
              headers={this.props.headers}
              data={this.props.products}
              handleQueryChange={this.handleFilterTextChange}
              handleSelection={this.handleFilterSelect}
            />
          </div>

          <br/>


          <div className="container">
            <div className="tags">
              {uiTags}
            </div>
          </div>
      </div>
    );
  }
}

export default SearchBar;
