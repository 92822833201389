import * as React from "react"
import "../styles/bulma.scss"
import Layout from "../components/layout"
import App from "../components/app"

const IndexPage = () => {

  return (
    <Layout>
    <main>
    <App/>
    </main>
    </Layout>
  )
}

export default IndexPage
